import React, { useState } from "react";
import untitle from "../images/untitled-1-1.png"
import layerig from "../images/layer-1.svg"
import line2 from "../images/line-2.svg"
import group82 from "../images/group-82.png"
import group821 from "../images/group-82-1.png"
import polygon from "../images/polygon-2.svg"
import mockup from "../images/macbook-air-mockup-1-1.png"
import file1 from "../images/file-1.png"
import file2 from "../images/file-2.png"
import file3 from "../images/file-3.png"
import file4 from "../images/file-4.png"
import file5 from "../images/file-5.png"
import fr4 from "../images/frame-18-4.svg"
import fr1 from "../images/frame-18-1.svg"
import fr2 from "../images/frame-18-2.svg"
import fr3 from "../images/frame-18-3.svg"
import fr5 from "../images/frame-18-5.svg"
import ig3 from "../images/image-3.png"
import ig1 from "../images/image-1.png"
import logocontain from "../images/logo-container.svg"
import copyright from "../images/copyright-1.svg"
import down1 from "../images/download-1.png"
import { useNavigate } from "react-router";
import { DASHBOARD_ROUTE, SIGNUP_ROUTE } from "../constants/RoutePath";
import './style1.css';  // Import the new style.css
import { authHeader, getTok } from "./common/mainfunctions";
export default function LandingPage(){
    const navigate=useNavigate()
    const [idx,setidx]=useState(1)
    const images = [file1, file1,file2,file3,file4,file5];
    const getAuthTokenFromCookie = () => {
      const name = 'authToken=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return null;
    };
    const getStartFun=()=>{
let t=localStorage.getItem("CURRENT") 
console.log(getTok(),t)

if (getTok()) {
  let url=`${t}/dashboard`
  localStorage.clear()
  window.open(url,"_self")
    }else{
      localStorage.clear()
      navigate(SIGNUP_ROUTE)
    }
  }
    const idxfun = (id) => {
        console.log(id,"called")
        setidx(id)
      };
    return(
        <>
       <div class="desktop">
      <div class="div">
      <div className="overlap" id="home">
          <div className="header">
          <a href="#home" className="text-wrapper">
          <img className="layer" src={layerig} />
          </a>
          <div className="frame">
            <div className="div-wrapper"><a href="#home" className="text-wrapper">Home</a></div>
            <div className="div-wrapper">
            <a href="#about-us" className="text-wrapper">About</a></div>
            <div className="div-wrapper"><a href="#features" className="text-wrapper">Features</a></div>
            <div className="div-wrapper"><a href="#integration" className="text-wrapper">Integration</a></div>
           
          </div>
          <button className="button">
            <button className="overlap-group" onClick={getStartFun}><button className="button-name">Login</button></button>
          </button>
          </div>
          <div class="BG"></div>

          <div className="rectangle"></div>
          <img class="untitled" src={untitle} />

          <div class="frame-2">
            <div class="frame-3"><p class="p">👋 Welcome to HR Assist</p></div>
            <p class="streamline-employee">Streamline Employee Onboarding and <br />Offboarding with Ease</p>
            <img class="line" src={line2} />
            <p class="HR-assist-empowers">
              HR Assist empowers your HR team to manage user accounts, assign M365 licenses, and update<br />
              employee data—all without IT involvement.
            </p>
          </div>
          <img class="group" src={group82} />
          <img class="img" src={group821} />
          <img class="polygon" src={polygon} />
          <div class="ellipse"></div>
          <div class="rectangle-2"></div>
          <div class="overlap-wrapper">
            <div class="overlap-2" id="about-us">
              <div class="frame-4">
                <div class="manage-your-projects">Efficient User Management</div>
                <p class="with-lots-of-unique">
                  HR Assist is a self-service tool designed to streamline the onboarding, updating, and offboarding of
                  employees, providing HR teams with a quick and efficient way to manage user accounts. Integrated with
                  Microsoft Entra ID, Pax8, and Intune, HR Assist ensures seamless user management and enhanced
                  operational efficiency.
                </p>
              </div>
              <div class="overlap-group-2" >
                <div class="ellipse-2"></div>
                <img class="macbook-air-mockup" src={mockup} />
              </div>
              <div class="frame-5"><div class="text-wrapper-2">ABOUT US</div></div>
            </div>
          </div>
          
        </div>
        <div class="overlap-group-wrapper" id="features" >
          <div class="overlap-3">
            <div class="frame-6">
              <div class="frame-7">
                <p class="manage-your-projects-2">Key Features of HR Assist</p>
                <p class="with-lots-of-unique-2">
                  Explore how HR Assist simplifies HR tasks with seamless user management, automated license
                  assignments,<br />and integrated workflows. Experience efficient, secure HR operations with our
                  powerful tool.
                </p>
              </div>
              <div class="frame-8">
                <div class="frame-9">
                  <img class="frame-10" src={fr4} />
                  <div class="frame-11">
                    <div class="organize-your-campai">Effortless User Management</div>
                    <p class="with-lots-of-unique-3">
                      Easily create, update, and offboard employee accounts with just a few clicks, streamlining your HR
                      processes.
                    </p>
                  </div>
                </div>
                <div class="frame-9">
                  <img class="frame-10" src={fr1} />
                  <div class="frame-11">
                    <div class="organize-your-campai">Seamless License Assignment</div>
                    <p class="with-lots-of-unique-3">
                      Quickly assign and manage Microsoft 365 licenses to ensure all employees have the tools they need
                      from day one.
                    </p>
                  </div>
                </div>
                <div class="frame-9">
                  <img class="frame-10" src={fr2} />
                  <div class="frame-11">
                    <div class="organize-your-campai">Streamlined Distribution Lists</div>
                    <p class="with-lots-of-unique-3">
                      Organize and manage distribution lists to facilitate effective communication and collaboration
                      within your organization.
                    </p>
                  </div>
                </div>
                <div class="frame-9">
                  <img class="frame-10" src={fr3} />
                  <div class="frame-11">
                    <div class="organize-your-campai">Approval-Based Workflows</div>
                    <p class="with-lots-of-unique-3">
                      Implement secure, approval-based workflows to ensure compliance and maintain control over HR
                      processes.
                    </p>
                  </div>
                </div>
                <div class="frame-9">
                  <img class="frame-10" src={fr4} />
                  <div class="frame-11">
                    <p class="organize-your-campai">Integrated with Microsoft Entra ID</p>
                    <p class="with-lots-of-unique-3">
                      Enjoy seamless integration with your Microsoft Entra ID, enhancing security and user management
                      without creating external accounts.
                    </p>
                  </div>
                </div>
                <div class="frame-9">
                  <img class="frame-10" src={fr5} />
                  <div class="frame-11">
                    <div class="organize-your-campai">Enhanced Security</div>
                    <p class="with-lots-of-unique-3">
                      Leverage integrations with Microsoft Entra ID, Pax8, and Intune for secure, efficient management
                      of users and devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="frame-12"><div class="text-wrapper-2">FEATURES</div></div>
          </div>
        </div>
        <div class="group-2">
          <div class="overlap-4">
            <div class="BG"></div>
            <img class="file" src={images[idx]} alt={`file-${idx}`} />
            <div class="frame-13">
              <div class="frame-7">
                <p class="manage-your-projects-2">Explore HR Assist in Action</p>
                <p class="with-lots-of-unique-2">
                  Discover how HR Assist streamlines HR tasks with intuitive user management and seamless integrations.
                  Our screenshots highlight key features that enhance efficiency and simplify operations.
                </p>
              </div>
              <div class="frame-wrapper">
              <div className="frame-14">
                <div className={idx === 1 ? "frame-15" : "frame-16"}><div className={idx === 1 ? "text-wrapper-3" : "text-wrapper-4"} onClick={()=>{idxfun(1)}} >
                    Dashboard Overview
                    </div></div>
                <div className={idx === 2 ? "frame-15" : "frame-16"} ><div className={idx === 2 ? "text-wrapper-3" : "text-wrapper-4"} onClick={()=>{idxfun(2)}}>License Management</div></div>
                <div className={idx === 3 ? "frame-15" : "frame-16"}><div className={idx === 3 ? "text-wrapper-3" : "text-wrapper-4"}onClick={()=>{idxfun(3)}}>Request Actions</div></div>
                <div className={idx === 4 ? "frame-15" : "frame-16"}><div className={idx === 4 ? "text-wrapper-3" : "text-wrapper-4"} onClick={()=>{idxfun(4)}}>User Management</div></div>
                <div className={idx === 5 ? "frame-15" : "frame-16"}><div className={idx === 5 ? "text-wrapper-3" : "text-wrapper-4"} onClick={()=>{idxfun(5)}}>Request Submission</div></div>
              </div>
            </div>
            </div>
            <div class="frame-17"><div class="text-wrapper-2">SCREENSHOTS</div></div>
          </div>
        </div>
        <div class="frame-18" id="integration">
          <div class="frame-19" >
            <div class="frame-20">
              <div class="frame-21"><div class="text-wrapper-2">INTEGRATION</div></div>
              <div class="frame-22">
                <div class="manage-your-projects-2">Integration Highlights</div>
                <p class="with-lots-of-unique-2">
                  HR Assist integrates with your Microsoft Entra ID, Pax8, and Intune for efficient user and software
                  management. Enjoy a unified platform for streamlined operations and effective device management.
                </p>
              </div>
            </div>
            <div class="frame-23">
              <div class="frame-24">
                <img class="img-2" src={ig3} />
                <div class="organize-your-campai-2">Microsoft Entra ID</div>
                <p class="with-lots-of-unique-2">
                  Leverages Microsoft Entra ID for efficient identity and access management.
                </p>
              </div>
              <div class="frame-24">
                <img class="image" src={ig1} />
                <div class="organize-your-campai-2">Pax8 Integration</div>
                <p class="with-lots-of-unique-2">Utilizes Pax8 for streamlined software and services management.</p>
              </div>
              <div class="frame-24">
                <img class="img-2" src={down1} />
                <div class="organize-your-campai-2">Intune Management</div>
                <p class="with-lots-of-unique-2">
                  Integrates with Intune to manage and secure devices, ensuring compliance and protection.
                </p>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <div class="bottom">
            <img class="logo-container" src={logocontain} />
            <div class="frame-25">
              <img class="copyright" src={copyright} />
              <p class="text-wrapper-5">Intelligent Information Solutions 2024 All Rights Reserved.</p>
            </div>
            <div class="logo-container"></div>
          </div>
        </footer>
      </div>
    </div>
        </>
    )
}