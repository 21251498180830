import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import {
  CHK_USER_NAME_EXIST,
  CREATE_NEW_APPROVAL_REQUEST_URL,
  DOMAIN_LIST_FROM_SETTINGS,
  LICENCE_REQUEST_DETAILS_BY_ID_URL,
  SYSTEM_LICENSE_LIST_URL,
  UPDATE_LICENCE_REQUEST_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader, getOrgId, getRole } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { getUserDetails } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import addusericon from "../../images/add user.png";
import * as Yup from "yup";
import CustomLoader from "../common/CustomLoader";
import oval from "../../images/Oval-white.png";
import deleteIcon from "../../images/deleteicon.png";
import { useState } from "react";
import { useEffect } from "react";
import boxicon from "../../images/boxicon.png";
import { MultiSelect } from "react-multi-select-component";
import { useRef } from "react";
import { SelectAll } from "@mui/icons-material";
import userblue from "../../images/user blue.png";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  domain: "Domain",
  group: "Group",
  department: "Department",
  officeLocation: "Office",
  jobTitle: "Job Title",
  license: "Select license",
  // role:'Role',
  grouparr: [],
  notifyemail: "",
};
export default function AddNewUserPopup(props) {
  const [loading, setLoading] = React.useState(false);
  const [personName, setPersonName] = React.useState([]);
  const offlist = useSelector((ol) => ol.OfficeListReducer);
  const deplist = useSelector((dl) => dl.DepartmentReducer);
  const rolelist = useSelector((rl) => rl.RoleListReducer);
  const jtlist = useSelector((jl) => jl.JobTitleReducer);
  // const domainList = useSelector((dl) => dl.DomainListReducer);
  const glist = useSelector((gl) => gl.DistributionGroupListReducer);
  const [domainList, setDomainList] = useState([]);
  const [licenses, setLicense] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState({});
  const [sids, setIds] = useState([]);
  const [selected, setSelected] = useState([]);
  const [variantName, setVariantName] = React.useState([]);
  const [mailErr, setMailErr] = useState(false);
  const [variantNameDef, setVariantNameDef] = React.useState([
    {
      id: "",
      name: "Select License",
      organizationId: "",
      price: "",
      pricingType: "",
      quantity: "",
      visibilityFlag: "",
      isDelete: "",
      createdDate: "",
      productId: "",
      licenseId: "",
      licenseSkuId: "",
      availableUnits: "",
    },
  ]);
  const { open, handleClose, editId } = props;
  const [showDoneButton, setShowDoneButton] = useState(false);
  const dispatch = useDispatch();
  const multiSelectRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dscl, setDoneClick] = useState("no");
  const handleDoneButtonClick = () => {
    setDropdownOpen(!dropdownOpen);
    setAnchorEl(null);

    multiSelectRef.current.focus(); // Remove focus
    // }
  };

  const [selectedone, setSelectedone] = useState([]);
  const [allItemsAreSelected, setAllItemsAreSelected] = useState(false);
  const selectLicense = (e) => {
    // setChkAll(false)
    const { checked, id } = e.target;
    let sku;
    if (checked == true) {
      sku = licenses.filter((r) => r.id == id);
    }
    setChecked((values) => ({
      ...values,
      [id]: checked,
    }));
    if (checked == true) {
      setIds([...sids, { id: id }]);
    } else {
      let ch = sids.filter((e) => e.id != id);
      setIds(ch);
    }
    setValues({
      ...values,
      license: licenses.filter((r) => r.id == id)[0].name,
    });

    let duplicateRemoved = [];

    sku &&
      sku.forEach((item) => {
        if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
          duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
        } else {
          duplicateRemoved.push(item);
        }
      });

    setVariantName(duplicateRemoved);
  };
  const handleChangeDGroup = (e) => {
    const val = e.target.value;
    const {
      target: { value },
    } = e;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    notifyemail: Yup.lazy(() => {
      return getRole() === "Admin"
        ? Yup.string().required("Email is required")
        : Yup.string(); // No validation if not Admin
    }),
    email: Yup.string()
      .trim()
      .test(
        "is-valid",
        "This username is taken. Try another.",
        function (value) {
          console.log("value", value, mailErr);
          const isValid = value != "" && mailErr == false;

          return isValid;
        }
      )
      .required("Email is required"),
    domain: Yup.string().test(
      "dom",
      "Domain is required",
      (value) => value != "Domain"
    ),
    // group:Yup.string().test("group", "Group is required", value => value != "Group"),
    // department: Yup.string().test(
    //   "department",
    //   "Department is required",
    //   (value) => value != "Department"
    // ),
    // officeLocation: Yup.string().test(
    //   "officeLocation",
    //   "Office is required",
    //   (value) => value != "Office"
    // ),
    // jobTitle: Yup.string().test(
    //   "jobTitle",
    //   "Job title is required",
    //   (value) => value != "Job Title"
    // ),
    license: Yup.string().test(
      "license",
      "License is required",
      (value) => value != "Select License"
    ),
  });
  const addNewUser = async (values) => {
    // multi license - variantName
    if (variantName.length > 0) {
      setLoading(true);
      let selone = selectedone.filter((el) => el.label != "done");
      const idsArray = selone.map((item) => item.id);
      let lname = "";
      let lid = "";
      let skuid = "";
      let flist;
      let tmp = variantName;
      let licenseIdsArray = tmp.map((item) => item.licenseId);
      let sku = variantName;
      let licenseskuids = sku.map((item) => item.licenseSkuId);
      let prodids = tmp.map((item) => item.productId);
      let lnamelist = variantName;
      let lnamelistfi = lnamelist.map((item) => item.name);
      console.log(prodids);
      if (sids.length > 0) {
        flist = licenses.filter((elem) => {
          return sids.some((ele) => {
            return parseInt(ele.id) === parseInt(elem.id);
          });
        });
      }
      if (flist) {
        lname = flist[0].name;
        lid = flist[0].licenseId;
        skuid = flist[0].licenseSkuId;
      }
      const options = {
        method: editId ? "PUT" : "POST",
        url: editId
          ? UPDATE_LICENCE_REQUEST_URL
          : CREATE_NEW_APPROVAL_REQUEST_URL,
        headers: authHeader(),
        data: editId
          ? {
              id: editId,
              department:
                values.department != "Department" ? values.department : null,
              firstName: values.firstName,
              jobTitle: values.jobTitle != "Job Title" ? values.jobTitle : null,
              email: values.email.concat("@").concat(values.domain),
              office:
                values.officeLocation != "Office"
                  ? values.officeLocation
                  : null,
              lastName: values.lastName,
              groupId: personName.toString(),
              licenseSkuId: licenseskuids.join(","),
              licenseId: licenseIdsArray.join(","),
              licenseName: lnamelistfi.join(","),
              requestType: "onboarding",
              approverId: "1",
              priority: "Medium",
              requestNotes: "Creating onboarding medium priority request",
              productId: prodids.join(","),
              credentialsReceiveEmail:getRole()=="Admin"?values.notifyemail:"",

            }
          : {
              department:
                values.department != "Department" ? values.department : null,
              firstName: values.firstName,
              jobTitle: values.jobTitle != "Job Title" ? values.jobTitle : null,
              email: values.email.concat("@").concat(values.domain),
              office:
                values.officeLocation != "Office"
                  ? values.officeLocation
                  : null,
              lastName: values.lastName,
              groupId: idsArray.toString(),
              licenseSkuId: licenseskuids.join(","),
              licenseId: licenseIdsArray.join(","),
              licenseName: lnamelistfi.join(","),
              requestType: "onboarding",
              approverId: "1",
              priority: "Medium",
              requestNotes: "Creating onboarding medium priority request",
              productId: prodids.join(","),
              credentialsReceiveEmail:getRole()=="Admin"?values.notifyemail:"",

            },
      };
      try {
        var apiResponse = await API(options);
        const { status, message } = apiResponse.data;
        if (status === "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          dispatch(getUserDetails());
          handleClose();
        }
      } catch (error) {
        if (error.response) {
          const { message } = error.response.data;
          CustomMessage(message, "error", enqueueSnackbar);
        }
      } finally {
        setLoading(false);
      }
    } else {
      CustomMessage(
        "Missing required parameters. Please provide all necessary information.",
        "error",
        enqueueSnackbar
      );
    }
  };
  const {
    handleSubmit,
    handleChange,
    setValues,
    // handleBlur,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addNewUser(values);
    },
  });
  const getDetailsById = async () => {
    const options = {
      method: "GET",
      url: LICENCE_REQUEST_DETAILS_BY_ID_URL,
      headers: authHeader(),
      params: {
        id: editId,
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, message, data } = apiResponse.data;
      if (status === "Success") {
        // setDetails(data)
        const {
          firstName,
          lastName,
          email,
          groupId,
          licenseId,
          licenseSkuId,
          department,
          office,
          jobTitle,
        } = data.requestedList || [];
        let lieditlist = licenses.filter(
          (r) => r.licenseSkuId == licenseSkuId
        )[0];
        let temp = [];
        let tid = lieditlist.id;

        temp.push(lieditlist);
        setVariantName(temp);
        setChecked((values) => ({
          ...values,
          [tid]: true,
        }));
        let arr = groupId.split(",");
        const filteredArray = glist.filter((item) => arr.includes(item.id));
        setValues({
          ...values,
          firstName: firstName,
          lastName: lastName,
          email: email.split("@")[0],
          domain: email.split("@")[1],
          group: groupId,
          department: department,
          officeLocation: office,
          jobTitle: jobTitle,
          grouparr: filteredArray,
          // license:licenses.filter((r)=>r.id==licenseId)[0].name,
        });
        setSelectedone(filteredArray);
        setPersonName(groupId.split(","));
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      // setSelected(true)
    }
  };

  useEffect(() => {
    if (editId) {
      getDetailsById();
    }
  }, [editId, licenses]);
  const getOrgLicenseList = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: SYSTEM_LICENSE_LIST_URL,
      params: {
        organizationId: getOrgId(),
      },
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        setLicense(data.list);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };
  useEffect(() => {
    getOrgLicenseList();
  }, []);

  const handleChangeFun = (event) => {
    const {
      target: { value },
    } = event;

    const filterdValue = value.filter(
      (item) => variantName.findIndex((o) => o.id === item.id) >= 0
    );

    let duplicatesRemoved = value.filter((item, itemIndex) =>
      value.findIndex((o, oIndex) => o.id === item.id && oIndex !== itemIndex)
    );

    // let map = {};

    // for (let list of value) {
    //   map[Object.values(list).join('')] = list;
    // }

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
      }
    });

    setVariantName(duplicateRemoved);
    const updatedChecked = {};
    duplicateRemoved.forEach((sid) => {
      updatedChecked[sid.id] = true;
    });
    setChecked(updatedChecked);
  };
  const customOptionRenderer = ({ checked, option, onClick }) => (
    <>
      <div key={option.value}>
        <label>
          <input
            style={{ opacity: option.label == "done" ? "0" : "" }}
            type="checkbox"
            className="option-checkbox"
            checked={option.label == "done" ? true : checked}
            onChange={() => {
              onClick(option);

              if (option.label != "done") {
                setDropdownOpen(false);
              }
              setShowDoneButton(true);
            }}
          />
          {option.label != "done" ? option.label : ""}
        </label>
      </div>
      <div>
        {showDoneButton && option === glist[glist.length - 1] && (
          <Box style={{ textAlign: "end" }}>
            <Button onClick={handleDoneButtonClick}>Done</Button>
          </Box>
        )}
      </div>
    </>
  );
  const handleSelect = (e) => {
    let sel = e.find((el) => el.label == "done");
    if (sel && allItemsAreSelected == true) {
      setSelectedone(glist);
    } else {
      setSelectedone(e);
    }
  };
  useEffect(() => {
    if (selectedone.length == glist.length) {
      setAllItemsAreSelected(true);
    } else {
      setAllItemsAreSelected(false);
    }
  }, [selectedone.length]);
  const selectDoneFun = (e) => {
    setDoneClick(e.currentTarget.dataset.flag);
    // setMenuOpen(true);
  };
  const handleInputClick = () => {
    if (dscl == "no") {
      setMenuOpen(true); // Open the menu when the input field is clicked
    } else if (dscl == "done") {
      setMenuOpen(false);
      setTimeout(() => {
        setDoneClick("no");
      }, 100);
    } else {
      setMenuOpen(true);
    }
  };
  useEffect(() => {
    if (dscl == "done") {
      handleInputClick();
    }
  }, [dscl]);

  const handleBlur = async () => {
    console.log("values", values);
    if (values.email != "" && values.domain != "Domain") {
      const options = {
        method: "GET",
        url: CHK_USER_NAME_EXIST,
        headers: authHeader(),
        params: {
          userPrincipalName: values.email.concat("@").concat(values.domain),
        },
      };
      try {
        const apiResponse = await API(options);
        const { message, status } = apiResponse.data;
        if (status == "Success") {
          setMailErr(false);
          setErrors({
            ...errors,
            email:
              values.email != ""
                ? "The username is available."
                : "Email is Required",
          });
          // CustomMessage(message, "success", enqueueSnackbar);
        }
        console.log(apiResponse, "response");
      } catch (error) {
        console.log("error", error);
        if (error.response) {
          const { message } = error.response.data;
          // CustomMessage(
          //   "This username is taken.Try another",
          //   "error",
          //   enqueueSnackbar
          // );
          setMailErr(true);
          setErrors({
            ...errors,
            email: "This username is taken. Try another.",
          });
        }
      }
    }
  };
  console.log("errp", errors);
  const getDomainList = async (id, search) => {
    // setLoading(true);
    const options = {
      method: "GET",
      headers: authHeader(),
      url: DOMAIN_LIST_FROM_SETTINGS,
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        setDomainList(data.domainList);
        // let val = data.domainList.filter((r) => r.isDefault == 1);
        // console.log(val, val[0].domainId, "val");
        // if (val) {
        //   setValues({ ...values, domain: val[0].domainId });
        // }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    getDomainList();
  }, []);
  return (
    <div>
      <BootstrapDialog
        className="rq-user-popup org-popup "
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ alignItems: "center", marginLeft: "233px" }}>
            <img src={addusericon} />
          </Box>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "16px",
              alignContent: "center",
              marginLeft: "220px",
            }}
          >
            {editId ? "Update User" : "Add New User"}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Stack
              style={{ marginTop: "10px", alignItems: "flex-start" }}
              sx={{ mb: 2 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInput
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                />
                <FormHelperText error={true}>
                  {errors.firstName && touched.firstName
                    ? errors.firstName
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInput
                  value={values.lastName}
                  name="lastName"
                  id="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                />
                <FormHelperText error={true}>
                  {errors.lastName && touched.lastName ? errors.lastName : ""}
                </FormHelperText>
              </FormControl>
            </Stack>

            <Stack
              style={{ marginTop: "10px", alignItems: "flex-start" }}
              sx={{ mb: 2 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInput
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={() => handleBlur("email")}
                  placeholder="Email"
                />
                <FormHelperText
                  error={true}
                  style={{
                    color:
                      errors.email === "The username is available."
                        ? "green"
                        : "#d32f2f",
                  }}
                >
                  {errors.email == "This username is taken. Try another." ||
                  errors.email == "The username is available."
                    ? errors.email
                    : errors.email && touched.email
                    ? errors.email
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInputSel
                  labelId="domain"
                  id="domain"
                  value={values.domain}
                  label="domain"
                  name="domain"
                  onChange={handleChange}
                  placeholder="domain"
                  data-fname="domain"
                  onBlur={() => handleBlur("email")}
                >
                  <MenuItem selected={true} disabled={true} value="Domain">
                    Domain
                  </MenuItem>
                  {domainList &&
                    domainList.map((rec, i) => {
                      return (
                        <MenuItem
                          key={rec.domainId}
                          name={rec.domainId}
                          value={rec.domainId}
                        >
                          {rec.domainId}
                        </MenuItem>
                      );
                    })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.domain && touched.domain ? errors.domain : ""}
                </FormHelperText>
              </FormControl>
            </Stack>
            <FormControl
              style={{ marginTop: "10px" }}
              sx={{
                width: "100%",
                minWidth: "500px",
                backgroundColor: "#f8f8f8",
              }}
              variant="standard"
            >
              <MultiSelect
                style={{ backgroundColor: "#f8f8f8" }}
                id="group"
                open={false}
                name="group"
                options={glist}
                // options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10",'11','12','13','14']}
                value={
                  selectedone.length == glist.length
                    ? selectedone
                    : selectedone.filter((el) => el.label != "done")
                }
                // value={selectedone}
                onChange={setSelectedone}
                labelledBy="Group"
                closeOnChangedValue={dropdownOpen}
                ItemRenderer={customOptionRenderer}
                // onMenuOpen={() => setDropdownOpen(true)}
                //   onMenuClose={() => setDropdownOpen(false)}
                overrideStrings={{
                  selectSomeItems: "Groups",
                  allItemsAreSelected:
                    selectedone.length == 2
                      ? selectedone.map((item) =>
                          item.label != "done" ? item.label : ""
                        )
                      : "All Groups are selected",
                  search: "Search",
                }}
                allItemsAreSelected={
                  glist.length != 2 && selectedone.length == glist.length - 1
                    ? true
                    : false
                }
              />
              <FormHelperText error={true}>
                {errors.group && touched.group ? errors.group : ""}
              </FormHelperText>
            </FormControl>

            {/* <FormControl
              style={{ marginTop: "10px" }}
              sx={{ width: "100%", minWidth: "500px" }}
              variant="standard"
            >
              <BootstrapInputSel
                labelId="group"
                id="group"
                multiple
                value={personName}
                label="group"
                name="group"
                onChange={handleChangeDGroup}
              >
                <MenuItem selected={true} disabled={true} value="Group">
                  Group
                </MenuItem>
                {glist &&
                  glist.map((rec, i) => {
                    return (
                      <MenuItem key={i} value={rec.id}>
                        {rec.displayName}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
              <FormHelperText error={true}>
                {errors.group && touched.group ? errors.group : ""}
              </FormHelperText>
            </FormControl> */}
            <Stack
              style={{ marginTop: "10px" }}
              sx={{ mb: 2 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInputSel
                  ref={multiSelectRef}
                  name="department"
                  id="department"
                  value={values.department}
                  onChange={handleChange}
                  placeholder="department"
                >
                  <MenuItem selected={true} disabled={true} value="Department">
                    Department
                  </MenuItem>

                  {deplist &&
                    deplist
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((rec, i) => {
                        return (
                          <MenuItem key={`depart_${i}`} value={rec.name}>
                            {rec.name}
                          </MenuItem>
                        );
                      })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.department && touched.department
                    ? errors.department
                    : ""}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <BootstrapInputSel
                  labelId="officeLocation"
                  id="officeLocation"
                  name="officeLocation"
                  value={values.officeLocation}
                  label="officeLocation"
                  onChange={handleChange}
                >
                  <MenuItem disabled={true} value={"Office"}>
                    Office
                  </MenuItem>

                  {offlist &&
                    offlist.map((rec, i) => {
                      return (
                        <MenuItem key={`off_loc_${i}`} value={rec.name}>
                          {rec.name}
                        </MenuItem>
                      );
                    })}
                </BootstrapInputSel>
                <FormHelperText error={true}>
                  {errors.officeLocation && touched.officeLocation
                    ? errors.officeLocation
                    : ""}
                </FormHelperText>
              </FormControl>
            </Stack>

            <FormControl sx={{ width: "100%" }} variant="standard">
              <BootstrapInputSel
                labelId="jobTitle"
                id="jobTitle"
                name="jobTitle"
                value={values.jobTitle}
                label="jobTitle"
                onChange={handleChange}
              >
                <MenuItem selected={true} disabled={true} value="Job Title">
                  Job Title
                </MenuItem>

                {jtlist &&
                  jtlist.map((rec, i) => {
                    return (
                      <MenuItem key={`job_${i}`} value={rec.jobTitle}>
                        {rec.jobTitle}
                      </MenuItem>
                    );
                  })}
              </BootstrapInputSel>
              <FormHelperText error={true}>
                {errors.jobTitle && touched.jobTitle ? errors.jobTitle : ""}
              </FormHelperText>
            </FormControl>
            {/*                            </Stack> */}
            <div>
              <FormControl
                style={{ marginTop: "10px" }}
                sx={{ width: "100%" }}
                variant="standard"
                label="License"
                placeholder="License"
              >
                <BootstrapInputSel
                  labelId="demo-multiple-checkbox-label"
                  id="license"
                  name="license"
                  multiple
                  value={variantName.length > 0 ? variantName : variantNameDef}
                  label="License"
                  placeholder="License"
                  MenuProps={{ open: menuOpen }}
                  data-flag="no"
                  inputProps={{ onFocus: handleInputClick }}
                  inputRef={inputRef}
                  input={
                    <OutlinedInput label="License" placeholder="license" />
                  }
                  renderValue={(selected) =>
                    selected.map((x) => x.name).join(", ")
                  }
                >
                  {licenses &&
                    licenses.map((rec) => (
                      <MenuItem key={rec.id} value={rec} data-flag="no">
                        <FormControlLabel
                          sx={{ margin: "0px !important" }}
                          id={rec.id}
                          skuname={rec.licenseSkuId}
                          name="exceptionRoleIdList"
                          onChange={selectLicense}
                          disabled={rec.subscriptionNotAvailableInPax8 == 1}
                          control={
                            <Checkbox
                              icon={<img src={boxicon} />}
                              id={rec.id}
                              checked={checked[rec.id]}
                              disabled={
                                rec.enabledUnits - rec.consumedUnits < 0
                                  ? true
                                  : false
                              }
                            />
                          }
                        />
                        <ListItemText>
                          <Box className="categorylic-item">
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={9}>
                                <Stack direction="row">
                                  <Stack direction="column">
                                    <Typography
                                      className="drop-title"
                                      style={{
                                        margin: "10px",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        lineHeight: "20px",
                                        color: "#000",
                                      }}
                                    >
                                      {rec.name}
                                    </Typography>
                                    <Typography
                                      style={{
                                        marginLeft: "10px",
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        lineHeight: "15px",
                                        color: "#000",
                                      }}
                                    >
                                      ${rec.price}/{rec.pricingType}
                                    </Typography>
                                    {rec.availableUnits > 0 ? (
                                      <Typography
                                        style={{
                                          margin: "10px",
                                          fontWeight: "700",
                                          fontSize: "12px",
                                          lineHeight: "15px",
                                          color: "#00A1F1",
                                        }}
                                      >
                                        {rec.availableUnits} available licenses
                                      </Typography>
                                    ) : (
                                      <Typography
                                        style={{
                                          margin: "10px",
                                          fontWeight: "700",
                                          fontSize: "12px",
                                          lineHeight: "15px",
                                          color: "#F65314",
                                        }}
                                      >
                                        No licenses available
                                      </Typography>
                                    )}
                                    {rec.subscriptionNotAvailableInPax8 == 1 ? (
                                      <Typography
                                        style={{
                                          margin: "10px",
                                          fontWeight: "700",
                                          fontSize: "12px",
                                          lineHeight: "15px",
                                          color: "#F65314",
                                        }}
                                      >
                                        Licenses have been removed from Pax8.
                                        Unable to select them.
                                      </Typography>
                                    ) : (
                                      <></>
                                    )}
                                    <Typography
                                      style={{
                                        margin: "10px",
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        lineHeight: "10px",
                                        color: "#F65314",
                                      }}
                                    >
                                      {rec.enabledUnits - rec.consumedUnits < 0
                                        ? `This license is in negative available counts.
                                         `
                                        : ""}
                                    </Typography>
                                    <Typography
                                      style={{
                                        marginLeft: "10px",
                                        fontWeight: "700",
                                        fontSize: "10px",
                                        lineHeight: "10px",
                                        color: "#F65314",
                                      }}
                                    >
                                      {rec.enabledUnits - rec.consumedUnits < 0
                                        ? `
                                         Enabled units is ${rec.enabledUnits} 
                                        but users consumed units are ${rec.consumedUnits}.`
                                        : ""}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ textAlign: "end" }}
                              >
                                <Stack direction="column">
                                  {rec.visibilityFlag == 1 ? (
                                    <Box
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#7CBB00",
                                        width: "80px",
                                        borderRadius: "10px",
                                        margin: "10px",
                                      }}
                                      data-id={rec.licenseId}
                                      data-flag={0}
                                      //  onClick={hideLicense}
                                    >
                                      <Stack
                                        direction="row"
                                        justifyContent="center"
                                      >
                                        <IconButton>
                                          <img src={oval}></img>
                                        </IconButton>
                                        <Typography
                                          style={{
                                            fontWeight: "700",
                                            color: "#FFF",
                                            fontSize: "12px",
                                            margin: "5px",
                                          }}
                                        >
                                          Show
                                        </Typography>
                                      </Stack>
                                    </Box>
                                  ) : (
                                    <Box
                                      style={{
                                        cursor: "pointer",
                                        backgroundColor: "#F4F4F4",
                                        width: "80px",
                                        borderRadius: "10px",
                                        margin: "10px",
                                      }}
                                      data-id={rec.licenseId}
                                      data-flag={1}
                                      // onClick={hideLicense}
                                    >
                                      <Stack
                                        direction="row"
                                        justifyContent="center"
                                      >
                                        <Typography
                                          style={{
                                            fontWeight: "700",
                                            color: "#000",
                                            fontSize: "12px",
                                            margin: "5px",
                                          }}
                                        >
                                          Hide
                                        </Typography>

                                        <IconButton>
                                          <img src={oval}></img>
                                        </IconButton>
                                      </Stack>
                                    </Box>
                                  )}
                                  <Box style={{ margin: "4px" }}>
                                    <IconButton
                                      data-id={rec.licenseId}
                                      // onClick={handleDelete}
                                    >
                                      {/* <img src={deleteIcon}></img> */}
                                    </IconButton>
                                  </Box>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                        </ListItemText>
                      </MenuItem>
                    ))}
                  <Button data-flag="done" onClick={selectDoneFun}>
                    Done
                  </Button>
                </BootstrapInputSel>
              </FormControl>
            </div>
            {getRole()=="Admin"?
            <>
            <br/>

              <InputLabel className="cred-inp">
              Enter the email address to send the login credentials for the specified user

                      </InputLabel>

            <FormControl sx={{ width: "100%" }} variant="standard">
                    
              {/* <InputLabel>
              Enter the email address to send the login credentials for the specified user
              </InputLabel> */}
                <BootstrapInput
                  name="notifyemail"
                  id="notifyemail"
                  value={values.notifyemail}
                  onChange={handleChange}
                  onBlur={() => handleBlur("notifyemail")}
                  placeholder="Email address"
                />
                <FormHelperText
                  error={true}
                >
                  {errors.notifyemail && touched.notifyemail
                    ? errors.notifyemail
                    : ""}
                </FormHelperText>
              </FormControl>
              </>:<></>}
            <Button
              type="submit"
              className="login-btn"
              size="medium"
              style={{
                marginLeft: "10px",
              }}
              onClick={handleSubmit}
            >
              <CustomLoader loading={loading} />
              SUBMIT
            </Button>
            <Button
              className="login-btn3"
              size="medium"
              style={{
                marginLeft: "10px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
