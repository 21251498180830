import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Bitmap from "../../images/Bitmap.png";
import Bitmappay8 from "../../images/Bitmap pay8.png";
import IntegrationWithAzure from "./Integration/IntegrationWithAzure";
import IntegrationWithPax from "./Integration/IntegrationWithPax";
import { useDispatch, useSelector } from "react-redux";
import intuneimg from "../../images/intune-intgr.svg";
import {
  AZURE_DISCONNECT_URL,
  DISCONNECT_PAX8_URL,
  SETTING_DEVICE_VISIBILITY_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader, getRole } from "../common/mainfunctions";
import API from "../common/API";
import { getUserDetails } from "../../redux/action";
import { useSnackbar } from "notistack";
import oval from "../../images/Oval.png";
import ovalgreen from "../../images/Oval-green.png";
import ovalwhite from "../../images/Oval-white.png";
import { styled } from "@mui/material/styles";
import CustomLoader from "../common/CustomLoader";
import MissingPermission from "./Permissions/MissingPermission";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  height: "40px",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  border: "1px solid rgba(0, 0, 0, 0.15)",
  fontWeight: "700",
  fontSize: "10px",
}));
const ItemOne = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  height: "auto",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  border: "1px solid rgba(0, 0, 0, 0.15)",
  fontWeight: "700",
  fontSize: "10px",
}));
export default function IntegrationList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [openPax, setOpenPax] = useState(false);
  const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const {
    msIntegrateFlag = "",
    disconnectFlag = "",
    pax8DisconnectFlag = "",
    pax8IntegrateFlag = "",
    intuneManagement = "",
    permissionUpdatedFlag = "",
  } = userDet || {};
  const [paxLoading, setPaxLoading] = useState(false);
  const [azloading, setAzloading] = useState(false);
  const [openmis, setOpenMis] = useState(false);
  const handleClose = () => {
    setOpenMis(false);
    dispatch(getUserDetails());
  };
  // const [intunseStatus, setIntuneStatus] = useState(intuneManagement);
  const setInte = () => {
    setOpen(!open);
  };
  const setPaxFlag = () => {
    setOpenPax(!openPax);
  };
  const disconnectAzureFun = async () => {
    setAzloading(true);
    const options = {
      method: "GET",
      url: AZURE_DISCONNECT_URL,
      headers: authHeader(),
    };
    try {
      var apiResponse = await API(options);
      const { status, message } = apiResponse.data;
      if (status === "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        dispatch(getUserDetails());
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setAzloading(false);
    }
  };
  const disconnectPaxFun = async () => {
    setPaxLoading(true);
    const options = {
      method: "GET",
      url: DISCONNECT_PAX8_URL,
      headers: authHeader(),
    };
    try {
      var apiResponse = await API(options);
      const { status, message } = apiResponse.data;
      if (status === "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        dispatch(getUserDetails());
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setPaxLoading(false);
    }
  };
  const enableDisableIntuneFun = async (e) => {
    let flag = e.currentTarget.dataset.status;
    let status = e.currentTarget.dataset.enable;
    console.log("status", status, intuneManagement);
    if (status == "enable" && permissionUpdatedFlag != 1) {
      setOpenMis(true);
    }
    // setPaxLoading(true);
    else {
      const options = {
        method: "GET",
        url: SETTING_DEVICE_VISIBILITY_URL,
        headers: authHeader(),
        params: {
          intuneStatus: flag,
        },
      };
      try {
        var apiResponse = await API(options);
        const { status, message } = apiResponse.data;
        if (status === "Success") {
          CustomMessage(message, "success", enqueueSnackbar);
          dispatch(getUserDetails());
        }
      } catch (error) {
        if (error.response) {
          const { message } = error.response.data;
          CustomMessage(message, "error", enqueueSnackbar);
        }
      } finally {
        // setPaxLoading(false);
      }
    }
  };
  return (
    <>
      <Grid container spacing={4} style={{ marginTop: "-20px" }}>
        <Grid item xs={12} md={6}>
          <Box
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              height: "250px",
            }}
          >
            <Stack direction="row" spacing={2} style={{ margin: "20px" }}>
              <Box sx={{ mt: "20px" }}>
                <img src={Bitmap} style={{ marginTop: "21px" }} />
              </Box>
              <Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-head"
                >
                  Entra ID
                </Typography>
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "5%",
                    maxWidth: "67px",
                    padding: "0px 5px",
                    height: "22px",
                  }}
                >
                  {msIntegrateFlag == 0 ||
                  msIntegrateFlag == null ||
                  disconnectFlag == 1 ? (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={oval}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">
                        Offline
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={ovalgreen}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">Online</Typography>
                    </Stack>
                  )}
                </Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-text"
                >
                  A complete identity and access management solution with
                  integrated security.
                </Typography>
              </Box>
            </Stack>
            <Box style={{ textAlign: "center", maxHeight: "80px" }}>
              {msIntegrateFlag == 0 ||
              msIntegrateFlag ==null||
              disconnectFlag == 1 ? (
                <Button
                  type="submit"
                  className="login-btn"
                  size="medium"
                  style={{
                    margin: "auto",
                    maxWidth: "91%",
                  }}
                  onClick={setInte}
                >
                  <Typography className="org-config-btn">CONNECT</Typography>
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="login-btn-disconnect"
                  size="medium"
                  style={{
                    maxWidth: "91%",
                    margin: "auto",
                  }}
                  onClick={disconnectAzureFun}
                >
                  <CustomLoader loading={azloading} />
                  <Typography className="org-disconnect-btn">
                    DISCONNECT
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        {/* pax 8 */}
        <Grid item xs={12} md={6}>
          <Box
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",

              height: "250px",
            }}
          >
            <Stack direction="row" spacing={2} style={{ margin: "20px" }}>
              <Box sx={{ mt: "20px" }}>
                <img src={Bitmappay8} style={{ marginTop: "21px" }} />
              </Box>
              <Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-head"
                >
                  Pax 8
                </Typography>
                {pax8IntegrateFlag == 0 ||
                pax8IntegrateFlag == null ||
                pax8DisconnectFlag == 1 ? (
                  <>
                    <Stack direction="row" spacing={2}>
                      <Item sx={{ padding: "0px 5px", height: "22px" }}>
                        <Stack direction="row">
                          <IconButton
                            sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                          >
                            <img src={oval}></img>
                          </IconButton>
                          <Typography className="org-onoff-text">
                            Offline
                          </Typography>
                        </Stack>
                      </Item>
                      <Item sx={{ padding: "0px 5px", height: "22px" }}>
                        <Stack direction="row">
                          <Typography
                            style={{
                              fontWeight: "700 !important",
                              fontSize: "10px",
                              marginTop: "3px",
                            }}
                          >
                            Not Configured
                          </Typography>
                        </Stack>
                      </Item>
                    </Stack>
                  </>
                ) : (
                  <Box
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: "5%",
                      maxWidth: "67px",
                      padding: "0px 5px",
                      height: "22px",
                    }}
                  >
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={ovalgreen}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">Online</Typography>
                    </Stack>
                  </Box>
                )}
                <Typography
                  style={{ marginTop: "10px" }}
                  className="org-ing-text"
                >
                  The Future of Could Management Empowering The Modern Partner
                  to Buy, Sell & Manage Could Products.
                </Typography>
              </Box>
            </Stack>
            <Box
              style={{
                maxHeight: "78px",
                textAlign: "center",
              }}
            >
              {pax8IntegrateFlag == 0 ||
              pax8IntegrateFlag == null ||
              pax8DisconnectFlag == 1 ? (
                <Button
                  type="submit"
                  className="login-btn1"
                  size="medium"
                  style={{
                    //  marginBottom:'20px',
                    maxWidth: "91%",
                    margin: "auto",
                    backgroundColor: "#7CBB00 !important",
                  }}
                  onClick={setPaxFlag}
                >
                  <Typography className="org-config-btn">CONFIGURE</Typography>
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="login-btn-disconnect"
                  size="medium"
                  style={{
                    // marginBottom:'20px',
                    maxWidth: "91%",
                    margin: "auto",
                  }}
                  onClick={disconnectPaxFun}
                >
                  <CustomLoader loading={paxLoading} />

                  <Typography className="org-disconnect-btn">
                    DISCONNECT
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",

              height: "250px",
            }}
          >
            <Stack direction="row" spacing={2} style={{ margin: "20px" }}>
              <Box sx={{ mt: "20px" }}>
                <img src={intuneimg} style={{ marginTop: "21px" }} />
              </Box>
              <Box>
                <Typography
                  style={{ marginTop: "20px" }}
                  className="org-ing-head"
                >
                  Intune Integration
                </Typography>
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "5%",
                    maxWidth: "67px",
                    padding: "0px 5px",
                    height: "22px",
                  }}
                >
                  {intuneManagement == 0 || intuneManagement == null ? (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={oval}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">
                        Offline
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row">
                      <IconButton
                        sx={{ padding: "5px 7px", paddingLeft: "2px" }}
                      >
                        <img src={ovalgreen}></img>
                      </IconButton>
                      <Typography className="org-onoff-text">Online</Typography>
                    </Stack>
                  )}
                </Box>
                <Typography
                  style={{ marginTop: "10px" }}
                  className="org-ing-text"
                >
                  Microsoft Intune is a cloud-based unified endpoint management
                  platform that empowers IT to manage, assess, and protect apps
                  and devices.
                </Typography>
              </Box>
            </Stack>
            <Box
              style={{
                maxHeight: "78px",
                textAlign: "center",
              }}
            >
              {intuneManagement == 0 || intuneManagement == null ? (
                <Button
                  type="submit"
                  className="login-btn1"
                  size="medium"
                  style={{
                    //  marginBottom:'20px',
                    maxWidth: "91%",
                    margin: "auto",
                    backgroundColor: "#7CBB00 !important",
                  }}
                  data-enable="enable"
                  data-status={intuneManagement == 1 ? 0 : 1}
                  onClick={enableDisableIntuneFun}
                >
                  <Typography className="org-config-btn">ENABLE</Typography>
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="login-btn-disconnect"
                  size="medium"
                  style={{
                    // marginBottom:'20px',
                    maxWidth: "91%",
                    margin: "auto",
                  }}
                  data-status={intuneManagement == 1 ? 0 : 1}
                  data-enable="disable"
                  onClick={enableDisableIntuneFun}
                >
                  <CustomLoader loading={paxLoading} />

                  <Typography className="org-disconnect-btn">
                    DISABLE
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        </Grid>

        {open && <IntegrationWithAzure open={open} handleClose={setInte} />}
        {openPax && (
          <IntegrationWithPax open={openPax} handleClose={setPaxFlag} />
        )}
        {console.log("open", openmis)}
        {openmis == true && getRole() == "Admin" ? (
          <MissingPermission
            open={openmis}
            handleClose={handleClose}
            // azureFlag={azureFlag}
            // setAzureFlag={setAzureFlag}
          />
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
